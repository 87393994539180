import "./App.css";
import Header from "./Components/Header";
import "./Assets/LineStyle.css";
import Body from "./Components/Body";

function App() {
    return (
        <div className="App">
            <div class="lines">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>

            <Header />
            <Body />
        </div>
    );
}

export default App;
