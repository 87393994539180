import React, { useEffect, useState } from "react";
import useStore from "../Store/store";
import axios from "axios";
import { CopyIcon } from "../Assets/icon";
import { Toaster, toast } from "react-hot-toast";
import ShortLinkList from "./ShortLinkList";

const Body = () => {
    const { setUrl } = useStore();
    const [longRUL, setLongURL] = useState("");
    const [currentCount, setCount] = useState(0);
    const [shortenedUrl, setShortenedUrl] = useState("");
    const apiUrl = "https://api.urlclip.io";
    const [copySuccess, setCopySuccess] = useState("");

    useEffect(() => {
        getCurrentCount();
    }, []);

    const handleInputChange = (event) => {
        setLongURL(event.target.value);
    };

    const getCurrentCount = async () => {
        try {
            const response = await axios.get(`${apiUrl}/total/count`);
            setCount(response.data);
        } catch (error) {
            console.error("Error getting count:", error);
        }
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.post(`${apiUrl}/shorten`, {
                originalUrl: longRUL,
            });
            const shortenedUrl = response.data.shortUrl;
            setUrl(longRUL);
            setShortenedUrl(shortenedUrl);
            getCurrentCount();
            toast.success("URL cliped successfully!");
        } catch (error) {
            console.error("Error submitting URL:", error);
        }
    };

    const handleCopyLink = () => {
        navigator.clipboard
            .writeText(`${apiUrl}/${shortenedUrl}`)
            .then(() => {
                setCopySuccess("Link copied!");
                toast.success("Link copied to clipboard!");
            })
            .catch((err) => {
                console.error("Error copying link: ", err);
                setCopySuccess("Failed to copy link.");
                toast.error("Failed to copy link.");
            });
    };

    return (
        <div className="body__center">
            <Toaster position="bottom-center" />
            <span className="title">Shrink It, Share It, Love It!</span>
            <span className="subtitle">
                Experience URLClip: the smart way to transform lengthy URLs into
                concise, shareable links.
            </span>

            <div class="searchbox-wrap">
                <input
                    type="text"
                    value={longRUL}
                    placeholder="Enter your URL here"
                    onChange={handleInputChange}
                />
                <button
                    className={!longRUL ? "disabled_button" : ""}
                    onClick={handleSubmit}
                    disabled={!longRUL}
                >
                    <span>Shrink</span>{" "}
                </button>
            </div>
            <div className="shorten_url">
                {shortenedUrl ? (
                    <span>
                        <div className="text_div" style={{ display: "flex" }}>
                            Your cliped URL:{" "}
                            <a
                                className="link_text"
                                href={`https://api.urlclip.io/${shortenedUrl}`}
                                target="_blank"
                            >
                                https://urlclip.com
                            </a>{" "}
                            <div
                                onClick={handleCopyLink}
                                className={"icon-class"}
                            >
                                <CopyIcon />
                            </div>
                        </div>
                    </span>
                ) : null}
            </div>
            <div className="total_shorten">
                <span className="total_shorten_text">{currentCount}</span>
            </div>
            {/* <div>
                <ShortLinkList />
            </div> */}
        </div>
    );
};

export default Body;
