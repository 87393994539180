import React from "react";
import { LogoIcon } from "../Assets/icon";

const Header = () => {
    return (
        <header>
            <div className="heder__section">
                <LogoIcon />
            </div>
        </header>
    );
};

export default Header;
